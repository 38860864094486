<template>
  <el-dialog
    :model-value="dialogVisible"
    title="角色授权"
    width="800px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-tabs
      v-model="activeName"
      class="demo-tabs"
      @tab-change="handleTabChange"
    >
      <el-tab-pane
        label="菜单"
        name="menu"
        style="max-height: 400px; overflow: auto"
      >
        <el-checkbox v-model="menuTreeData.CanAdd" label="新增" size="large" />
        <el-checkbox v-model="menuTreeData.CanEdit" label="编辑" size="large" />
        <el-checkbox
          v-model="menuTreeData.CanDelete"
          label="删除"
          size="large"
        />
        <el-checkbox
          v-model="menuTreeData.CanUpload"
          label="上传"
          size="large"
        />
        <el-checkbox
          v-model="menuTreeData.CanDownload"
          label="下载"
          size="large"
        />
        <el-tree
          ref="menuTreeRef"
          :data="menuTreeData.trees"
          show-checkbox
          default-expand-all
          node-key="id"
          highlight-current
          v-loading="menuLoading"
          element-loading-background="rgba(122, 122, 122, 0)"
          :default-checked-keys="menuTreeData.checkedID"
        />
      </el-tab-pane>
      <el-tab-pane label="设备" name="device">
        <el-transfer
          v-model="deviceSelectList"
          :props="{
            key: 'ID',
            label: 'Name'
          }"
          :data="deviceList"
          :titles="['未授权设备', '已授权设备']"
          v-loading="deviceLoading"
          element-loading-background="rgba(122, 122, 122, 0)"
        />
      </el-tab-pane>
      <el-tab-pane label="场景" name="scence">
        <el-transfer
          v-model="scenesSelectList"
          :props="{
            key: 'ID',
            label: 'Name'
          }"
          :data="scenesList"
          :titles="['未授权场景', '已授权场景']"
          v-loading="scenesLoading"
          element-loading-background="rgba(122, 122, 122, 0)"
        />
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {} from '@/api/Role'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import { GetMenuTree, SetMenuPermission } from '@/api/Menu'
import { GetDeviceFillList, SetDevicePermission } from '@/api/Device'
import { GetScenesFillList, SetScenesPermission } from '@/api/Scenes'
import { el } from 'element-plus/es/locale'
const RoleID = ref('')
const dialogVisible = ref(false)
const activeName = ref('menu')
const menuTreeData = ref({
  CanAdd: false,
  CanEdit: false,
  CanDelete: false,
  CanUpload: false,
  CanDownload: false,
  trees: [],
  checkedID: []
})
const menuTreeRef = ref(null)
const menuLoading = ref(false)
const deviceList = ref([])
const deviceSelectList = ref([])
const deviceLoading = ref(false)
const scenesList = ref([])
const scenesSelectList = ref([])
const scenesLoading = ref(false)
const props = defineProps({
  rowID: {
    type: String,
    default: '',
    required: true
  }
})

onMounted(() => {})
const emits = defineEmits(['update:modelValue'])
const handleOpen = () => {
  RoleID.value = props.rowID
  InitMenuTree()
  InitDeviceList()
  InitScenceList()
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  SetMenuPermission({
    RoleID: props.rowID,
    PermissionIDList: menuTreeRef.value.getCheckedKeys(false),
    CanEdit: menuTreeData.value.CanEdit,
    CanDelete: menuTreeData.value.CanDelete,
    CanAdd: menuTreeData.value.CanAdd,
    CanUpload: menuTreeData.value.CanUpload,
    CanDownload: menuTreeData.value.CanDownload
  })
    .then((res) => {
      ElMessage({
        showClose: true,
        message: '菜单授权成功',
        type: 'success'
      })
      handleClose()
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `菜单授权失败:${err}`,
        type: 'error'
      })
    })

  SetDevicePermission({
    RoleID: props.rowID,
    PermissionIDList: deviceSelectList.value
  })
    .then((res) => {
      ElMessage({
        showClose: true,
        message: '设备授权成功',
        type: 'success'
      })
      handleClose()
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `设备授权失败:${err}`,
        type: 'error'
      })
    })

  SetScenesPermission({
    RoleID: props.rowID,
    PermissionIDList: scenesSelectList.value
  })
    .then((res) => {
      ElMessage({
        showClose: true,
        message: '场景授权成功',
        type: 'success'
      })
      handleClose()
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `场景授权失败:${err}`,
        type: 'error'
      })
    })
}
const handleTabChange = (e) => {
  // if (e == 'menu') {
  //   InitMenuTree()
  // } else if (e == 'device') {
  //   InitDeviceList()
  // } else if (e == 'scence') {
  //   InitScenceList()
  // }
}
const InitMenuTree = () => {
  menuTreeData.value = {
    CanAdd: false,
    CanEdit: false,
    CanDelete: false,
    CanUpload: false,
    CanDownload: false,
    trees: [],
    checkedID: []
  }
  menuLoading.value = true
  GetMenuTree({ checkRoleID: props.rowID })
    .then((res) => {
      menuTreeData.value = res.Data
      menuTreeRef.value.setCheckedKeys(menuTreeData.value.checkedID, false)
      menuLoading.value = false
    })
    .catch((err) => {
      console.log(err)
      menuLoading.value = false
    })
}
const InitDeviceList = () => {
  deviceList.value.length = 0
  deviceSelectList.value.length = 0
  deviceLoading.value = true
  GetDeviceFillList({ key: '', authRoleID: props.rowID })
    .then((res) => {
      res.Data.forEach((element) => {
        deviceList.value.push(element)
        if (element.HasAuth) deviceSelectList.value.push(element.ID)
      })
      deviceLoading.value = false
    })
    .catch((err) => {
      console.log(err)
      deviceLoading.value = false
    })
}
const InitScenceList = () => {
  scenesList.value.length = 0
  scenesSelectList.value.length = 0
  scenesLoading.value = true
  GetScenesFillList({ key: '', authRoleID: props.rowID })
    .then((res) => {
      res.Data.forEach((element) => {
        scenesList.value.push(element)
        if (element.HasAuth) scenesSelectList.value.push(element.ID)
      })
      scenesLoading.value = false
    })
    .catch((err) => {
      console.log(err)
      scenesLoading.value = false
    })
}
</script>
