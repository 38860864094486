<template>
  <div class="content">
    <TitleInfo title1="系统管理" title2="角色管理"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>角色名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入角色名称"
            class="keyInput"
          />
          <div>角色编码：</div>
          <el-input
            v-model="queryForm.code"
            placeholder="请输入角色编码"
            class="keyInput"
          />
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button
            v-if="user.CanAdd"
            :icon="Plus"
            type="warning"
            :round="false"
            @click="handleDialogValue()"
            >新增</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="Name" label="角色名称"></el-table-column>
          <el-table-column prop="Code" label="角色编码"></el-table-column>
          <el-table-column prop="Order" label="类型序号"></el-table-column>
          <el-table-column
            prop="action"
            label="操作"
            width="300"
            v-if="user.CanDelete || user.CanEdit"
          >
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
              <el-button
                v-if="user.CanDelete"
                size="small"
                type="danger"
                :icon="Delete"
                @click="del(row)"
                >删除</el-button
              >
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="success"
                :icon="Lock"
                @click="empower(row.ID)"
                >授权</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowValue="rowInfo"
      v-if="dialogVisible"
      @initList="initGetList"
    ></Dialog>
    <EmpowerDialog
      v-model="empowerDialogVisible"
      :rowID="empowerRowID"
      v-if="empowerDialogVisible"
    ></EmpowerDialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  Search,
  Refresh,
  Plus,
  Edit,
  Delete,
  Lock
} from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetRolePageList, DeleteRole } from '@/api/Role'
import Dialog from './components/dialog.vue'
import EmpowerDialog from './components/empowerDialog.vue'
import { useStore } from 'vuex'
const queryForm = ref({
  name: '',
  code: '',
  pageIndex: 1,
  pageSize: 10
})
const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const empowerDialogVisible = ref(false)
const empowerRowID = ref('')
const dialogTitle = ref('')
const rowInfo = ref({})
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  initGetList()
})
const initGetList = async () => {
  GetRolePageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加角色'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改角色信息'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前角色?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteRole({ id: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => {})
}
const empower = (id) => {
  empowerRowID.value = id
  empowerDialogVisible.value = true
}
const resetInfo = () => {
  queryForm.value = {
    name: '',
    code: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
