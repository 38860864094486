import $http from '@/utitls/request'
/**
 * 获取菜单树
 */
export const GetMenuTree = (data) => {
    return $http.get('/api/Menu/GetMenuTree', { params: data })
}
/**
 * 获取菜单信息
 */
export const GetMenuList = (data) => {
    return $http.get('/api/Menu/GetMenuList', { params: data })
}
/**
 * 设置菜单权限
 */
export const SetMenuPermission = (data) => {
    return $http.post('/api/Menu/SetMenuPermission', data)
}